<template>
  <div>
    <van-cell title="就诊人" :value="userName" />

    <van-cell title="收费员" :value="PaidList.operatorName" />
    <van-cell title="开立时间" :value="PaidList.creationTime" />
    <van-cell title="支付状态" v-if="PaidList.state === 1">
      <template slot="default">
        <span style="color: red">未支付</span>
      </template>
    </van-cell>
    <van-cell title="支付状态" v-else-if="PaidList.state === 2">
      <template slot="default">
        <span style="color: #57c4b7">已支付</span>
      </template>
    </van-cell>
    <van-cell title="类型" :value="PaidList.title" />
    <van-cell title="hisRegNO" :value="PaidList.hisRegNo" />

    <van-cell title="银医订单号" :value="PaidList.orderId" />
    <van-cell title="支付总金额" :value="PaidList.cost + '元'" />
    <van-cell title="自费金额" :value="PaidList.selfCost + '元'" />

    <van-cell title="医保个账金额" :value="PaidList.ybCost + '元'" />
    <van-cell title="医保金额" :value="PaidList.psnCost + '元'" />
    <van-cell title="交易描述" :value="PaidList.content" />

    <!-- </van-cell-group>
      <van-cell-group v-for="item in list" :key="item + 'OrdIn'"> -->

    <!-- <van-list style="margin-top: 5px">
        <van-cell-group>
          <van-cell title="项目名称">
            <template slot="default">
              <span style="color: #57c4b7">金额(元)</span>
            </template>
          </van-cell>
          <van-cell v-for="Ditem in list" :key="Ditem + 'OrdIn'">
            <template slot="title">
              <span style="color: #969799">{{ Ditem.payName }}</span>
              <span style="float:right">{{ Ditem.totalCost }}元</span>
            </template>
            <template slot="label">
              <div v-for="(item,index) in Ditem.clinicDetails" :key="index">
              <span>{{ item.itemName }}</span>
              <span style="float:right">{{ item.totalCost }}元</span>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list> -->
    <div
      style="
          font-size: 12px;
          color: #d3d3d3;
          margin: 1% 40%;
          width: auto;
          height: 40px;
        "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import ajax from "../../lib/ajax";
import store from "../../store";
export default {
  data() {
    return {
      userName: "",
      PaidList: {},
      loading: false,
      finished: false,
      feeTotal: 0,
      orderId: "",
    };
  },
  created() {
    this.userName = store.getters["store/userName"];
    this.orderId = this.$route.query.orderId;
    console.log("获取到的openID:", this.$route.query.orderId);
    this.onLoad();
  },
  methods: {
    onLoad() {
      console.log(this.orderId);
      ajax
        .post(`/Api/WxYBPayServices/List`, {
          begin: "2022-12-15T09:01:16.071Z",
          end: "2023-12-10T09:01:16.071Z",
          state: 2,
          oid: this.orderId,
          medoid: "",
          hisRegno: "",
          username: this.PatientName,
        })
        .then((res) => {
          console.log(res);
          this.PaidList = res.data.result[0];
          console.log(this.PaidList);
        })
        .catch(() => {
          Toast("请求失败，请稍后重试！");
        });
    },
  },
};
</script>

<style></style>
